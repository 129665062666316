import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Tooltip } from './Tooltip';

/**
 * @param {object} props - Props.
 * @param {string} [props.id] - An ID for the tooltip button.
 * @param {string} [props.title] - A title for the tooltip button.
 * @param {JSX.Element} props.primaryTooltipContent - The contents of the
 * tooltip.
 * @param {JSX.Element} [props.secondaryTooltipContent] - The contents of the
 * secondary tooltip (if required).
 * @param {boolean} [props.externalTooltip] - True if the tooltip should be a
 * floating element (useful when overflow is hidden on a parent element which
 * means you can't see the tooltip), else false (default).
 * @param {boolean} [props.suppressFocusStyles] - True if the default focus
 * styles are to be suppressed in favour of some other custom styles, else
 * false (default).
 * @param {boolean} [props.showOnHover] - True if we want to show the tooltip on
 * hover, otherwise false.
 * @param {'light'|'dark'} [props.theme] - Theme.
 * @param {'above'|'below'} [props.direction] - Direction.
 * @param {boolean} [props.disabled] - True if the button is disables, else
 * false.
 * @param {boolean} [props.hideArrow] - True if the arrow should be hidden,
 * else false.
 * @param {boolean} [props.noPadding] - True if we wish to remove the default
 * tooltip padding, else false.
 * @param {Function} [props.onClick] - A click event handler.
 * @param {object} [props.classNames] - CSS class name(s).
 * @param {any} props.children - The contents of the tooltip.
 * @param {boolean} props.noTransform - True if you wish the dropdown content not transform its positioning once created with inline style.
 * @param {string} props.template - The template identifier if more complex tooltip content is required and is to be rendered through a separate component.
 * @returns {JSX.Element} Component.
 */
export const TooltipButton = ({
    id,
    title,
    primaryTooltipContent,
    secondaryTooltipContent,
    template,
    externalTooltip = false,
    suppressFocusStyles = false,
    showOnHover = true,
    hideArrow = false,
    noPadding = false,
    noTransform = false,
    theme = 'light',
    direction = 'above',
    classNames = '',
    children,
    ...otherProps
}) => {
    const [isOpen, setIsOpen] = useState(false);

    /** @type {React.MutableRefObject<HTMLDivElement|null>} */
    const wrapper = useRef();

    /** @type {React.MutableRefObject<HTMLDivElement|null>} */
    const externalTooltipWrapper = useRef();

    /** @type {React.MutableRefObject<HTMLDivElement|null>} */
    const tooltipTriggerButtonRef = useRef();

    /**
     * 1. Show
     *
     * @param {KeyboardEvent} event - Keyup event.
     */
    const keyupEventHandler = ({ key }) => {
        if (key !== 'Tab') {
            return;
        }

        if (showOnHover && isOpen) {
            setIsOpen(
                showOnHover && wrapper.current.contains(document.activeElement)
            );
        }
    };

    const showTooltip = () => {
        if (isOpen && otherProps.disabled && title !== null) {
            return true;
        } else if (isOpen && !otherProps.disabled && title !== null) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        window.addEventListener('keyup', keyupEventHandler);

        // Append an empty element to act as a wrapper in which the tooltip can
        // be rendered.
        if (externalTooltip) {
            externalTooltipWrapper.current = document.createElement('div');
            document.body.appendChild(externalTooltipWrapper.current);
        }

        return () => {
            window.removeEventListener('keyup', keyupEventHandler);

            externalTooltipWrapper.current &&
                document.body.removeChild(externalTooltipWrapper.current);
        };
    }, []);

    return (
        <div
            className={classnames(
                `tooltip-button ${classNames?.component ?? ''}`,
                {
                    'tooltip-button--suppress-focus-styles':
                        !!suppressFocusStyles
                }
            )}
            ref={wrapper}
            onMouseEnter={() => showOnHover && setIsOpen(true)}
            onMouseLeave={() => showOnHover && setIsOpen(false)}
        >
            <button
                {...otherProps}
                title={title}
                className={`tooltip-button__primary-tooltip-button ${
                    classNames?.button ?? ''
                }`}
                onClick={(evt) => {
                    setIsOpen(!isOpen);
                    otherProps.onClick && otherProps.onClick(evt);
                }}
                aria-labelledby={title}
                ref={tooltipTriggerButtonRef}
            >
                {children}
            </button>

            {showTooltip() && (
                // @ts-ignore
                <Tooltip
                    id={id}
                    triggerRef={tooltipTriggerButtonRef}
                    direction={direction}
                    theme={theme}
                    hideArrow={hideArrow}
                    noPadding={noPadding}
                    noTransform={noTransform}
                    tooltipTarget={wrapper.current}
                    externalTooltipWrapper={externalTooltipWrapper?.current}
                    primaryTooltipContent={primaryTooltipContent}
                    template={template}
                    secondaryTooltipContent={secondaryTooltipContent}
                    className={classNames?.tooltip ?? ''}
                    closeTooltip={() => {
                        if (
                            showOnHover &&
                            wrapper.current.contains(document.activeElement)
                        ) {
                            return;
                        }

                        setIsOpen(false);
                    }}
                />
            )}
        </div>
    );
};

TooltipButton.propTypes = {
    id: PropTypes.string.isRequired,
    primaryTooltipContent: PropTypes.any.isRequired,
    secondaryTooltipContent: PropTypes.any,
    children: PropTypes.any.isRequired,
    classNames: PropTypes.object,
    onClick: PropTypes.func,
    externalTooltip: PropTypes.bool,
    suppressFocusStyles: PropTypes.bool,
    showOnHover: PropTypes.bool,
    hideArrow: PropTypes.bool,
    noPadding: PropTypes.bool,
    noTransform: PropTypes.bool,
    theme: PropTypes.oneOf(['light', 'dark']),
    direction: PropTypes.oneOf(['above', 'below', 'auto']),
    disabled: PropTypes.bool,
    title: PropTypes.string,
    template: PropTypes.string
};
