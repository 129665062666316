/* eslint-disable react/jsx-key */
import React from 'react';

import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table';
import { useBreakpoints } from 'react-breakpoints-hook';

import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { ShowMoreButton } from './ShowMoreButton';
import { StatsTable } from 'widgets/statspro/js/components/common/stats-table/StatsTable';
import { getTranslation } from 'common/react/utils/translations';
import { MobileTable, MobileTableBody, MobileTableHead } from './MobileTable/';

const PAGE_SIZE = 15;

export const Table = ({
    columns,
    data,
    fullData,
    modifier,
    update,
    seasonPid,
    sortByFavourites,
    setSortByFavourites,
    onPlayerFavouriteClick,
    filteredResults,
    favourites,
    leaderTotal,
    isBrownlowV2,
    isBrownlowTracker,
    isFetching,
    hideLoadMoreBtn,
    showPredictedData,
    primaryTooltipComponent
}) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                disableSortRemove: true,
                disableSortBy: true
            },
            useSortBy
        );

    let { lg } = useBreakpoints({
        lg: { min: 1206, max: null }
    });

    // ===== Render ========================================================= //

    if (lg) {
        return (
            <StatsTable
                modifier={modifier}
                getTableProps={getTableProps}
                nextPageButton={
                    !hideLoadMoreBtn && data.length ? (
                        <ShowMoreButton
                            canNextPage={
                                data &&
                                fullData &&
                                data.length < fullData.length
                            }
                            totalRowCount={fullData.length}
                            pageSize={PAGE_SIZE}
                            currentPageSize={data.length}
                            onClick={update}
                            isBrownlowV2={isBrownlowV2}
                            isBrownlowTracker
                            isFetching={isFetching}
                        />
                    ) : null
                }
            >
                <TableHead
                    headerGroup={headerGroups[0]}
                    sortByFavourites={sortByFavourites}
                    setSortByFavourites={setSortByFavourites}
                />
                {data?.length === 0 && filteredResults ? (
                    <tbody>
                        <tr>
                            <td
                                colSpan={columns.length}
                                className="stats-table__cell stats-table__cell--no-player-votes"
                            >
                                <p className="stats-section-error">
                                    {getTranslation(
                                        'label.brownlow.noPlayerVotes'
                                    )}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <TableBody
                        rows={rows}
                        prepareRow={prepareRow}
                        getTableBodyProps={getTableBodyProps}
                        seasonPid={seasonPid}
                        onPlayerFavouriteClick={onPlayerFavouriteClick}
                        favourites={favourites}
                        leaderTotal={leaderTotal}
                        isBrownlowV2={isBrownlowV2}
                        isBrownlowTracker={isBrownlowTracker}
                        showPredictedData={showPredictedData}
                        primaryTooltipComponent={primaryTooltipComponent}
                    />
                )}
            </StatsTable>
        );
    }

    return (
        <MobileTable
            seasonPid={seasonPid}
            isBrownlowV2={isBrownlowV2}
            nextPageButton={
                !hideLoadMoreBtn && data.length ? (
                    <ShowMoreButton
                        canNextPage={
                            data && fullData && data.length < fullData.length
                        }
                        totalRowCount={fullData.length}
                        pageSize={PAGE_SIZE}
                        currentPageSize={data.length}
                        onClick={update}
                        isBrownlowV2={isBrownlowV2}
                        isFetching={isFetching}
                    />
                ) : null
            }
        >
            <MobileTableHead
                headerGroup={headerGroups[0]}
                sortByFavourites={sortByFavourites}
                setSortByFavourites={setSortByFavourites}
            />
            {data?.length === 0 && filteredResults ? (
                <div className="brownlow-tracker-leaderboard-mobile-table__body-row brownlow-tracker-leaderboard-mobile-table__body-row-top">
                    <div className="brownlow-tracker-leaderboard-mobile-table__body-cell brownlow-tracker-leaderboard-mobile-table__body-error-cell">
                        {getTranslation('label.brownlow.noPlayerVotes')}
                    </div>
                </div>
            ) : (
                <MobileTableBody
                    rows={rows}
                    prepareRow={prepareRow}
                    onPlayerFavouriteClick={onPlayerFavouriteClick}
                    favourites={favourites}
                    seasonPid={seasonPid}
                    leaderTotal={leaderTotal}
                    isBrownlowV2={isBrownlowV2}
                    isBrownlowTracker={isBrownlowTracker}
                    showPredictedData={showPredictedData}
                />
            )}
        </MobileTable>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    fullData: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired,
    modifier: PropTypes.string,
    cssClass: PropTypes.string,
    seasonPid: PropTypes.string,
    sortByFavourites: PropTypes.bool,
    setSortByFavourites: PropTypes.func,
    onPlayerFavouriteClick: PropTypes.func,
    filteredResults: PropTypes.bool,
    favourites: PropTypes.array,
    leaderTotal: PropTypes.number,
    isBrownlowV2: PropTypes.bool,
    isBrownlowTracker: PropTypes.bool,
    isFetching: PropTypes.bool,
    hideLoadMoreBtn: PropTypes.bool,
    showPredictedData: PropTypes.bool,
    primaryTooltipComponent: PropTypes.func
};
