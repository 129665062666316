import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    PlayerModalDataSelector,
    playerModalSelector
} from 'store/modules/brownlow-tracker/player-modal/selectors';
import FocusTrap from 'focus-trap-react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import {
    FETCH_PLAYER_MODAL_DATA,
    UPDATE_PLAYER_MODAL
} from 'store/modules/brownlow-tracker/player-modal/actions';
import { PlayerModalRoundByRound } from './components/player-modal-round-by-round';
import { PlayerModalDetails } from './components/player-modal-details';
import { PlayerModalPhoto } from './components/player-modal-photo';
import {
    FOCUSABLE_TOOLTIP_CLOSE,
    FOCUSABLE_TOOLTIP_OPEN
} from 'common/react/components/tooltip-button/focusable-tooltip';

/**
 * Brownlow Tracker Player Modal
 * Shows the player details and table of votes round by round
 *
 * @returns
 */
export const PlayerModal = () => {
    const dispatch = useDispatch();
    const modal = useSelector(playerModalSelector);
    const isVisible = modal.visible;
    const data = useSelector(PlayerModalDataSelector);

    const [focusTrapPaused, setFocusTrapPaused] = useState(false);

    const brPlayerModalRef = useRef();

    const closeModal = () => {
        dispatch(
            UPDATE_PLAYER_MODAL.request({
                visible: false
            })
        );
    };

    useEffect(() => {
        // Handle esc key
        const handleEsc = (event) => {
            if (event.keyCode === PULSE.app.common.CONSTANTS.KEY_CODES.ESCAPE) {
                closeModal();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    useEffect(() => {
        if (modal.seasonPid && (!data || data.seasonPid !== modal.seasonPid)) {
            dispatch(
                FETCH_PLAYER_MODAL_DATA.request({
                    seasonPid: modal.seasonPid
                })
            );
        }
    }, [modal.seasonPid]);

    /**
     * Focusable tooltip open listener
     * pauses the FocusTrap to allow for focus to jump into the tooltip
     */
    const onFocusableTooltipOpen = () => {
        setFocusTrapPaused(true);
    };

    /**
     * Focusable tooltip close listener
     * resumes FocusTrap
     */
    const onFocusableTooltipClose = () => {
        setFocusTrapPaused(false);
    };

    /**
     * Setting listeners for FOCUSABLE_TOOLTIP_ events
     * These are dispatched from the FocusableTooltipWrapper component:
     * - FOCUSABLE_TOOLTIP_OPEN - when the tooltip is mounted
     * - FOCUSABLE_TOOLTIP_CLOSE - when the tooltip is unmounted
     */
    useEffect(() => {
        window.addEventListener(FOCUSABLE_TOOLTIP_OPEN, onFocusableTooltipOpen);
        window.addEventListener(
            FOCUSABLE_TOOLTIP_CLOSE,
            onFocusableTooltipClose
        );

        // removing listeners on modal unmount
        return () => {
            window.removeEventListener(
                FOCUSABLE_TOOLTIP_OPEN,
                onFocusableTooltipOpen
            );
            window.removeEventListener(
                FOCUSABLE_TOOLTIP_CLOSE,
                onFocusableTooltipClose
            );
        };
    }, []);

    return (
        <>
            {isVisible && (
                <div className="brownlow-player-modal">
                    <div
                        className="brownlow-player-modal__overlay"
                        onClick={() => closeModal()}
                    ></div>
                    <FocusTrap
                        focusTrapOptions={{
                            initialFocus: false,
                            clickOutsideDeactivates: true
                        }}
                        paused={focusTrapPaused}
                    >
                        <div
                            className="brownlow-player-modal__container"
                            ref={brPlayerModalRef}
                        >
                            <div className="brownlow-player-modal__modal-close-wrapper">
                                <button
                                    data-modal-action="close"
                                    className="brownlow-player-modal__modal-close"
                                    onClick={() => closeModal()}
                                >
                                    <SvgIcon
                                        icon="close"
                                        className="brownlow-player-modal__modal-close-icon"
                                    />
                                    <span className="u-screen-reader">
                                        {getTranslation('label.close')}
                                    </span>
                                </button>
                            </div>

                            <div className="brownlow-player-modal__header">
                                <div className="brownlow-player-modal__background-container">
                                    <div className="brownlow-player-modal__background"></div>
                                    <div className="brownlow-player-modal__watermark"></div>
                                    <div className="brownlow-player-modal__background-scrim"></div>
                                </div>
                                <PlayerModalDetails
                                    seasonPid={modal.seasonPid}
                                    playerId={modal.playerId}
                                />
                                <PlayerModalPhoto />
                            </div>

                            <PlayerModalRoundByRound />
                        </div>
                    </FocusTrap>
                </div>
            )}
        </>
    );
};
