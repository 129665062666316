/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import { StatsTableBody } from 'widgets/statspro/js/components/common/stats-table/StatsTableBody';
import { PlayerCell } from './PlayerCell';

/**
 * @param {object} props - Props.
 * @param {object[]} props.rows - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @param props.seasonPid
 * @param props.onPlayerFavouriteClick
 * @param props.favourites
 * @param props.leaderTotal
 * @param props.isBrownlowV2
 * @param props.showPredictedData
 * @param props.primaryTooltipComponent
 * @param props.isBrownlowTracker
 * @returns {JSX.Element} Component.
 */
export const TableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    seasonPid,
    onPlayerFavouriteClick,
    favourites,
    leaderTotal,
    isBrownlowV2,
    isBrownlowTracker,
    showPredictedData,
    primaryTooltipComponent
}) => {
    return (
        <StatsTableBody
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            valueWrapper={true}
            showPredictedData={showPredictedData}
            isBrownlowV2={isBrownlowV2}
            isBrownlowTracker={isBrownlowTracker}
            getCellId={(row, cell) => getId(cell, row)}
            getCellTitle={(row, cell) => getTitle(cell, row)}
            getTrProps={(row) => {
                let classes = 'stats-table__body-row';

                if (
                    row.original.player.winner ||
                    row.original.totalVotes === leaderTotal
                ) {
                    classes = classes + ' stats-table__body-row--is-leader';
                }

                if (!row.original.player.eligible) {
                    classes =
                        classes +
                        ' stats-table__body-row stats-table__body-row--is-ineligible';
                }

                return {
                    className: classes
                };
            }}
            primaryTooltipComponent={primaryTooltipComponent ?? null}
            customCells={{
                player: (row, cell, cellProps) => (
                    <PlayerCell
                        {...cellProps}
                        player={cell.value}
                        onPlayerFavouriteClick={onPlayerFavouriteClick}
                        favourites={favourites}
                        cell={cell}
                        seasonPid={seasonPid}
                    />
                ),
                totalVotes: (row, cell, cellProps) => (
                    <td
                        {...cellProps}
                        className="stats-table__total-votes-cell"
                    >
                        <div>{cell.value}</div>
                        {showPredictedData && (
                            <div className="stats-table__cell-predicted">
                                {row.original.totalPredictedVotes}
                            </div>
                        )}
                    </td>
                ),
                bettingOdds: (row, cell, cellProps) =>
                    row.allCells[0].value.eligible ? (
                        <td
                            {...cellProps}
                            className="stats-table__betting-odds-cell"
                        >
                            <span>
                                {new Intl.NumberFormat('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD'
                                }).format(cell.value)}
                            </span>
                        </td>
                    ) : (
                        <td
                            {...cellProps}
                            className="stats-table__betting-odds-cell"
                        >
                            <span>–</span>
                        </td>
                    )
            }}
        />
    );
};

TableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    seasonPid: PropTypes.string,
    onPlayerFavouriteClick: PropTypes.func,
    favourites: PropTypes.array,
    leaderTotal: PropTypes.number,
    isBrownlowV2: PropTypes.bool,
    isBrownlowTracker: PropTypes.bool,
    showPredictedData: PropTypes.bool,
    primaryTooltipComponent: PropTypes.func
};

const getId = (cell, row) =>
    `brownlow-leaderboard-${row.original.player.givenName}-${row.original.player.surname}-${cell.column.id}`;

const getTitle = (cell, row) =>
    `${row.original.player.givenName} ${row.original.player.surname}: ${
        cell.value.actual ? cell.value.actual : cell.value ? cell.value : '0'
    } votes.`;
