import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from './components/Table';
import { useSelector } from 'react-redux';
import {
    PlayerModalPredictedSelector,
    playerModalSelector
} from 'common/store/modules/brownlow-tracker/player-modal/selectors';
import { composeColumnData } from './utils';
import { useGetMatchesQuery } from 'common/store/modules/api/afl-api';
import { Loader } from 'common/react/components/Loader';
import FocusableTooltipArea from 'common/react/components/tooltip-button/focusable-tooltip/FocusableTootlipArea';

/**
 * Table of votes round by round
 * Shows Round Number, Opponent team, predicted votes and actual votes for each round.
 *
 * @param {object} props
 * @param {object} props.data Player data from leaderboard api
 * @param {Array} props.rounds List of all rounds in the season
 *
 * @returns
 */
export const RoundVotesTable = ({ data, rounds }) => {
    const playerModalData = useSelector(playerModalSelector);
    const predictedData = useSelector(PlayerModalPredictedSelector);

    /**
     * looking for predicted votes for the player
     */
    const playerPredictedData = useMemo(() => {
        return predictedData?.players?.find(
            (item) => item.providerId === playerModalData.playerId
        );
    }, [predictedData, playerModalData]);

    /**
     * once predicted votes for the player are found
     * fetch matches for the player's team in the season
     */
    const {
        data: matches,
        isLoading,
        isFetching,
        isUninitialized
    } = useGetMatchesQuery(
        {
            pageSize: 25,
            compSeasonId: predictedData?.compSeason.id,
            teamId: playerPredictedData?.teamId
        },
        {
            skip: !predictedData || !playerPredictedData
        }
    );

    // colums definition
    const columns = composeColumnData();

    /**
     * rows definition
     * combines all season rounds, with actual votes, and the finaly predicted votes
     * excludes rounds that are not Byes and have no opponent - as there will be nothing usefull to display for that round
     */
    const rows = useMemo(() => {
        // filtering rounds that are not finals
        const combinedRounds =
            rounds
                ?.filter((round) =>
                    PULSE.app.common.match.isNonFinalRound(round)
                )
                .map((round) => {
                    // finding votes for the round
                    const votesRound = data?.roundByRoundVotes?.find(
                        (votesRoundData) =>
                            votesRoundData.roundNumber === round.roundNumber
                    );

                    // finding predicted votes for the round
                    const predictedRound =
                        playerPredictedData?.rounds?.[round.roundNumber];

                    // finding a match by round
                    const match = matches?.find(
                        (matchData) =>
                            matchData.round.roundNumber === round.roundNumber
                    );

                    let opponent;
                    if (match) {
                        // definding opponent out of the match data
                        opponent = {
                            matchPid: match.providerId,
                            matchId: match.id,
                            ...(match.home.team.id ===
                            playerPredictedData.teamId
                                ? match.away.team
                                : match.home.team)
                        };
                    }

                    // was the round a Bye
                    const bye = !predictedRound?.played && predictedRound?.bye;

                    return {
                        roundNumber: round.roundNumber,
                        player: playerModalData,
                        opponent: opponent,
                        predictedPoints: bye
                            ? '-'
                            : predictedRound?.points ?? 0,
                        votes: bye ? '-' : votesRound?.votes ?? 0,
                        bye: bye
                    };
                })
                .reverse() ?? [];

        // filtering out bye rounds and rounds where the team didn't play
        return combinedRounds.filter((round) => round.opponent || round.bye);
    }, [data, rounds, playerPredictedData, playerModalData, matches]);

    if (isLoading || isFetching || isUninitialized) {
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    }

    return (
        <div className="brownlow-player-modal__round-votes-table">
            <FocusableTooltipArea>
                <Table
                    columns={columns}
                    data={rows}
                    seasonPid={playerModalData.seasonPid}
                />
            </FocusableTooltipArea>
        </div>
    );
};

RoundVotesTable.propTypes = {
    data: PropTypes.object,
    rounds: PropTypes.array
};
