import React, { useEffect, useMemo, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    leaderboardNavSelector,
    leaderboardFilterSelector,
    leaderboardFavouriteSelector
} from 'common/store/modules/brownlow-tracker/leaderboard/selectors';
import {
    useGetBrownlowPredictorQuery,
    useGetBrownlowPredictorSearchQuery
} from 'common/store/modules/api/afl-api';
import { Table } from 'widgets/brownlow-tracker/js/components/Leaderboard/components/table/components/Table';
import { Loader } from 'common/react/components/Loader';
import { EmptyState } from 'common/react/components/EmptyState';
import {
    composeColumnData,
    composeRowData
} from 'widgets/brownlow-tracker/js/components/Leaderboard/components/table/utils';
import { UPDATE_FAVOURITE_PLAYER } from 'common/store/modules/brownlow-tracker/leaderboard/actions';
import { sortFavourites } from '../utils/utils';
import { useGetTeamsQuery } from 'common/store/modules/api/afl-api';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { useGetBrownlowBettingOddsQuery } from 'common/store/modules/api/cfs-api';

import { usePagination } from 'widgets/brownlow-tracker/js/components/hooks/use-fetch-more-data';

const PAGE_SIZE = 20;

export const PredictorCurrentRoundContext = createContext('');

export const BrownlowPredictor = ({ seasonId, seasonPid }) => {
    const [data, setData] = useState([]);
    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const [sortByFavourites, setSortByFavourites] = useState(false);
    const [hideLoadMoreBtn, setHideLoadMoreBtn] = useState(false);

    const favourites = useSelector(leaderboardFavouriteSelector);
    const leaderboardFilter = useSelector(leaderboardFilterSelector);

    const dispatch = useDispatch();
    const nav = useSelector(leaderboardNavSelector);

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const season = seasons.find((item) => item.id === nav.season.id);
    const seasonPrId = season?.providerId ?? '';
    const rounds = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list ?? []
    );
    const { data: teamsData } = useGetTeamsQuery(nav.season.id, {
        skip: !nav.season.id ? true : false
    });

    const teams = teamsData || [];
    const team = teams.find((item) => item.id === nav.team.id);
    const teamId = team?.providerId ?? '';

    const teamFilter = nav.team.id;

    const [
        shownData,
        fetchMoreData,
        currentPage,
        setShownData,
        setCurrentPage
    ] = usePagination(data, PAGE_SIZE, teamId);

    const { data: bettingOddsData } = useGetBrownlowBettingOddsQuery(null, {
        pollingInterval: PULSE.app.common.CONSTANTS.TIME.ONE_MINUTE_IN_MS
    });
    const shouldShowBettingOdds =
        useBettingVis() &&
        bettingOddsData?.displayInApp === true &&
        bettingOddsData?.outcomes?.length > 0;

    const teamIdFromFilters = teamFilter !== -1 ? teamFilter.toString() : '';

    const {
        data: players,
        isLoading,
        isFetching
    } = useGetBrownlowPredictorQuery(
        {
            compSeasonId: season ? season?.id : seasonId,
            page: currentPage - 1,
            team: teamIdFromFilters
        },
        {
            skip: !(
                seasonId &&
                (leaderboardFilter.query === null ||
                    leaderboardFilter.query === '')
            )
        },
        { refetchOnMountOrArgChange: true }
    );

    const {
        data: searchPlayers,
        isLoading: isLoadingSearch,
        isFetching: isFetchingSearch
    } = useGetBrownlowPredictorSearchQuery(
        {
            compSeasonId: seasonId,
            page: currentPage - 1,
            term: leaderboardFilter.query
        },
        {
            skip:
                leaderboardFilter.query === null ||
                leaderboardFilter.query === ''
        }
    );

    /**
     * On Player Favourite Click
     * Modify favourites on player add/remove
     *
     * @param {*} playerId
     */
    const onPlayerFavouriteClick = (playerId) => {
        dispatch(
            UPDATE_FAVOURITE_PLAYER.request({
                id: null,
                providerId: playerId
            })
        );
    };

    /**
     * Set row data
     */
    useEffect(() => {
        setNoDataAvailable(false);

        if (!players?.results?.length && !searchPlayers?.results?.length) {
            setNoDataAvailable(true);
            setShownData([]);
            return;
        }

        const playersData =
            leaderboardFilter.query && leaderboardFilter.query !== ''
                ? searchPlayers
                : players;

        setHideLoadMoreBtn(playersData?.newItems?.length <= PAGE_SIZE);

        let items =
            playersData?.results?.map((item) => {
                const roundByRoundVotes = Object.keys(item.rounds).map(
                    (key) => ({
                        matchId: '',
                        roundNumber: key,
                        votes: item.rounds[key]?.points ?? ''
                    })
                );

                const teamObject = teams.find(
                    (teamItem) => teamItem.id === item.teamId
                );

                return {
                    eligible: item.eligible ?? false,
                    player: {
                        ...item
                    },
                    roundByRoundVotes: roundByRoundVotes,
                    team: {
                        teamAbbr: teamObject?.abbreviation,
                        teamId: teamObject?.providerId,
                        teamName: teamObject?.name,
                        teamNickname: teamObject?.nickname
                    },
                    totalVotes: item.totalVotes ?? '',
                    bettingOdds: shouldShowBettingOdds
                        ? bettingOddsData?.outcomes?.find(
                              (outcome) =>
                                  item?.providerId === outcome?.playerId
                          )?.price ?? null
                        : null
                };
            }) ?? [];

        if (favourites.length && sortByFavourites) {
            items = sortFavourites(items, favourites, teamId);
        }

        setData(items);
    }, [
        favourites,
        sortByFavourites,
        teamId,
        bettingOddsData?.outcomes,
        shouldShowBettingOdds,
        seasonPrId,
        setShownData,
        teamsData,
        players,
        searchPlayers,
        leaderboardFilter
    ]);

    // set the shown data based on the filtered results
    useEffect(() => {
        setShownData(data.slice(0, PAGE_SIZE * currentPage));
    }, [data, setShownData, currentPage]);

    /**
     * Get columns
     */
    const columns = useMemo(() => composeColumnData(rounds), [rounds]);

    /**
     * Get rows
     */
    const rowData = useMemo(
        () =>
            shownData.map((item) => {
                const yearFromCompSeason = seasonPrId.replace('CD_S', '');
                const playerFromProviderId = item.player.providerId.replace(
                    'CD_I',
                    ''
                );
                let headshotUrl;

                if (yearFromCompSeason) {
                    headshotUrl = `https:${
                        PULSE.app.environment.playerImagePath
                    }/${'AFL'}/${yearFromCompSeason}/${playerFromProviderId}.png?im=Scale,width=0.6,height=0.6`;
                }

                item.player.photoURL = headshotUrl;

                return composeRowData(
                    item,
                    columns.filter(
                        (col) =>
                            !['player', 'totalVotes', 'bettingOdds'].includes(
                                col.accessor
                            )
                    ),
                    rounds,
                    true,
                    players?.results
                );
            }),
        [columns, shownData, rounds, seasonPrId, players?.results]
    );

    /**
     * Resets the current page when the team filter is being used
     * so that the new results start from the top.
     */
    useMemo(() => {
        setCurrentPage(1);
    }, [teamId]);

    // ===== Render ============================================== //

    if (isLoading || isLoadingSearch) {
        // Loader for waiting for data
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    } else if (noDataAvailable) {
        // Error handling for when there is no data
        return (
            <div className="career-and-season-stats__empty-state">
                <EmptyState
                    titleTranslation="label.brownlow.tracker.error.noDataAvailable"
                    summaryTranslation="label.brownlow.tracker.error.noDataAvailable.summary"
                />
            </div>
        );
    }

    const currentRoundNumber = season?.currentRoundNumber;

    return (
        <PredictorCurrentRoundContext.Provider value={currentRoundNumber}>
            <Table
                columns={columns}
                data={rowData}
                fullData={players.results}
                modifier={`brownlow-tracker-leaderboard brownlow-leaderboard stats-table--brownlow-predictor ${
                    shouldShowBettingOdds
                        ? 'stats-table--brownlow-tracker-leaderboard--show-odds'
                        : ''
                }`}
                update={fetchMoreData}
                key={rowData[0]?.player.playerId}
                seasonPid={seasonPid}
                favourites={favourites}
                sortByFavourites={sortByFavourites}
                setSortByFavourites={setSortByFavourites}
                onPlayerFavouriteClick={onPlayerFavouriteClick}
                filteredResults={leaderboardFilter?.query?.length > 0}
                isBrownlowV2={true}
                isFetching={isFetching || isFetchingSearch}
                hideLoadMoreBtn={hideLoadMoreBtn}
            />
        </PredictorCurrentRoundContext.Provider>
    );
};

BrownlowPredictor.propTypes = {
    seasonId: PropTypes.string.isRequired,
    seasonPid: PropTypes.string
};
